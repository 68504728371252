package apace.coltivainarmonia.web.pages.index

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.components.ColtivareText
import apace.coltivainarmonia.web.utils.R
import apace.coltivainarmonia.web.utils.ScaleUpKeyframes
import apace.coltivainarmonia.web.utils.Theme
import apace.coltivainarmonia.web.utils.WobbleKeyframes
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.AnimationDirection
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@Composable
fun TopDownloadNowBanner(
    modifier: Modifier = Modifier,
    ctx: PageContext
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        ColtivareText(
            modifier = Modifier.padding(bottom = 8.px).animation(
                WobbleKeyframes.toAnimation(
                    duration = 1.s,
                    iterationCount = AnimationIterationCount.Infinite,
                    timingFunction = AnimationTimingFunction.EaseInOut,
                    direction = AnimationDirection.Alternate,
                )
            ),
            color = Theme.colors.coltivareLightYellow,
            fontWeight = FontWeight.Bold,
            text = "Scaricala ora, senza costi!",
        )
        Button(
            modifier = Modifier.size(0.px).animation(
                ScaleUpKeyframes.toAnimation(
                    duration = 1.s,
                    iterationCount = AnimationIterationCount.Infinite,
                    timingFunction = AnimationTimingFunction.EaseInOut,
                    direction = AnimationDirection.Alternate,
                )
            ),
            onClick = {
                ctx.router.navigateTo("https://play.google.com/store/apps/details?id=apace.coltivainarmonia")
            }
        ) {
            Image(
                modifier = Modifier.height(64.px),
                src = R.drawable.playStore
            )
        }
    }
}