package apace.coltivainarmonia.web.utils

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.theme.colors.ColorScheme

object Theme {

    object type {
        val balooda = "BaloodaRegular"
        val fraunces = "FrauncesRegular"
    }

    object colors {
        val coltivarePrimary = Color.rgb(0x1c045c)
        val coltivarePrimaryLighter = Color.rgb(0x31147d)
        val coltivarePrimaryLightest = Color.rgb(0x503499)
        val coltivareGreen = Color.rgb(0x81C784)
        val coltivareLightYellow = Color.rgb(0xFDF2AA)
        val white = Color.rgb(0xF5F5F5)
    }

    object colorSchemes {
        object coltivareGreen : ColorScheme {
            override val _50  = Color.rgb(0xE8F5E9)
            override val _100 = Color.rgb(0xC8E6C9)
            override val _200 = Color.rgb(0xA5D6A7)
            override val _300 = Color.rgb(0x81C784)
            override val _400 = Color.rgb(0x66BB6A)
            override val _500 = Color.rgb(0x4CAF50)
            override val _600 = Color.rgb(0x43A047)
            override val _700 = Color.rgb(0x388E3C)
            override val _800 = Color.rgb(0x2E7D32)
            override val _900 = Color.rgb(0x1B5E20)
        }
        object white : ColorScheme {
            override val _500 = Color.rgb(0xFFFFFF)
            override val _50 =  Color.rgb(0xb0a9d4)
            override val _100 = Color.rgb(0xb0a9d4)
            override val _200 = Color.rgb(0xb0a9d4)
            override val _300 = Color.rgb(0xb0a9d4)
            override val _400 = Color.rgb(0xb0a9d4)
            override val _600 = Color.rgb(0xb0a9d4)
            override val _700 = Color.rgb(0xb0a9d4)
            override val _800 = Color.rgb(0xb0a9d4)
            override val _900 = Color.rgb(0xb0a9d4)
        }
    }

}