package apace.coltivainarmonia.web.pages.item_pages

import androidx.compose.runtime.*
import apace.coltivainarmonia.web.backgroundLinearGradient
import apace.coltivainarmonia.web.components.Card
import apace.coltivainarmonia.web.components.ColtivareButton
import apace.coltivainarmonia.web.components.ColtivareText
import apace.coltivainarmonia.web.components.objects.BoxShadow
import apace.coltivainarmonia.web.components.objects.LinearGradient
import apace.coltivainarmonia.web.pages.index.AppFeaturesBody
import apace.coltivainarmonia.web.pages.index.BottomItemsBody
import apace.coltivainarmonia.web.pages.index.MainTitleBody
import apace.coltivainarmonia.web.pages.index.TopDownloadNowBanner
import apace.coltivainarmonia.web.utils.FadeInKeyFrames
import apace.coltivainarmonia.web.utils.R
import apace.coltivainarmonia.web.utils.ScaleUpKeyframes
import apace.coltivainarmonia.web.utils.Theme
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.CSSTextShadow
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaHouse
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button

@Composable
fun ExtraItemPageBody(item: ItemPage) {
    val ctx = rememberPageContext()

    Box(
        modifier = Modifier.fillMaxSize().backgroundColor(Theme.colors.coltivarePrimaryLightest),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundLinearGradient(
                        LinearGradient(
                            from = Theme.colors.coltivarePrimaryLighter,
                            to = Theme.colors.coltivarePrimary
                        )
                    ),
                contentAlignment = Alignment.Center
            ) {
                MainTitleBody()
                TopDownloadNowBanner(
                    modifier = Modifier.padding(24.px).align(Alignment.TopEnd),
                    ctx = ctx
                )
                Box(
                    modifier = Modifier.padding(16.px).align(Alignment.TopStart),
                ) {
                    ColtivareButton(
                        onClick = { ctx.router.navigateTo("/") },
                    ) {
                        FaHouse(
                            modifier = Modifier.color(Colors.White),
                            size = IconSize.LG
                        )
                    }
                }
            }

            Box(
                modifier = Modifier.fillMaxWidth().backgroundColor(Theme.colors.coltivarePrimaryLighter),
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth().padding(top = 24.px, bottom = 16.px).align(Alignment.TopCenter),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    ColtivareText(
                        text = item.pageTitle, // Se vuoi scoprire quando | Desideri conoscere quando | Hai dubbi su quando | Vorresti sapere quando | La luna risponde
                        fontSize = 32.px,
                        fontWeight = FontWeight.Bold,
                        color = Colors.White
                    )
                    ColtivareText(
                        modifier = Modifier.padding(bottom = 16.px),
                        text = item.operationDescription,
                        fontSize = 18.px,
                        fontWeight = FontWeight.Bold,
                        color = Colors.White
                    )
                    Row(
                        modifier = Modifier.gridColumn(3).flexWrap(FlexWrap.Wrap).padding(leftRight = 32.px),
                    ) {
                        item.operationMonths.forEach { monthName ->
                            Box(modifier = Modifier.padding(leftRight = 8.px, topBottom = 8.px)) {
                                Card(color = Colors.White) {
                                    ColtivareText(
                                        modifier = Modifier.padding(topBottom = 4.px, leftRight = 8.px),
                                        text = monthName
                                    )
                                }
                            }
                        }
                    }
                }
            }

            Box(
                modifier = Modifier.padding(24.px).fillMaxWidth().backgroundLinearGradient(
                    LinearGradient(
                        from = Theme.colors.coltivarePrimary,
                        to = Theme.colors.coltivarePrimaryLightest
                    )
                ),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = Modifier.padding(bottom = 24.px),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    ColtivareText(
                        modifier = Modifier.padding(24.px),
                        text = ItemPages.EXTRA_STRING,
                        fontSize = 24.px,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        color = Theme.colors.coltivareLightYellow
                    )

                    var iconShadow by remember { mutableStateOf(0.px) }
                    var animateIcon: Boolean by remember { mutableStateOf(false) }

                    ColtivareButton(
                        modifier = Modifier.size(128.px)
                            .onMouseEnter {
                                iconShadow = 8.px
                                animateIcon = true
                            }.onMouseLeave {
                                iconShadow = 0.px
                                animateIcon = false
                            },
                        onClick = {
                            ctx.router.navigateTo("https://play.google.com/store/apps/details?id=apace.coltivainarmonia")
                        }
                    ) {
                        Image(
                            modifier = Modifier
                                .size(128.px)
                                .borderRadius(24.px)
                                .animation(
                                    ScaleUpKeyframes.toAnimation(
                                        duration = 1.s,
                                        iterationCount = AnimationIterationCount.Infinite,
                                        timingFunction = AnimationTimingFunction.EaseInOut,
                                        direction = AnimationDirection.Alternate,
                                    )
                                )
                                .then(
                                    BoxShadow(
                                        offsetY = iconShadow.times(0.75),
                                        blurRadius = iconShadow.times(1.5),
                                        spreadRadius = iconShadow.times(0.5),
                                        color = Colors.Black.copy(alpha = 75)
                                    ).toBoxShadow()
                                ),
                            src = R.drawable.appIcon
                        )
                        Spacer()
                    }
                }
            }
        }
    }
}