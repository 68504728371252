package apace.coltivainarmonia.web.pages

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.pages.item_pages.ExtraItemPageBody
import apace.coltivainarmonia.web.pages.item_pages.ItemPages
import com.varabyte.kobweb.core.Page

@Page(routeOverride = ItemPages.Pomodori.PAGE_PATH) @Composable
fun PomodoriExtraItemPage() = ExtraItemPageBody(ItemPages.Pomodori)
@Page(routeOverride = ItemPages.Fagiolini.PAGE_PATH) @Composable
fun FagioliniExtraItemPage() = ExtraItemPageBody(ItemPages.Fagiolini)
@Page(routeOverride = ItemPages.Zucchine.PAGE_PATH) @Composable
fun ZucchineExtraItemPage() = ExtraItemPageBody(ItemPages.Zucchine)
@Page(routeOverride = ItemPages.Melanzane.PAGE_PATH) @Composable
fun MelanzaneExtraItemPage() = ExtraItemPageBody(ItemPages.Melanzane)
@Page(routeOverride = ItemPages.Fagioli.PAGE_PATH) @Composable
fun FagioliExtraItemPage() = ExtraItemPageBody(ItemPages.Fagioli)
@Page(routeOverride = ItemPages.Cetrioli.PAGE_PATH) @Composable
fun CetrioliExtraItemPage() = ExtraItemPageBody(ItemPages.Cetrioli)
@Page(routeOverride = ItemPages.Prezzemolo.PAGE_PATH) @Composable
fun PrezzemoloExtraItemPage() = ExtraItemPageBody(ItemPages.Prezzemolo)
@Page(routeOverride = ItemPages.Carota.PAGE_PATH) @Composable
fun CarotaExtraItemPage() = ExtraItemPageBody(ItemPages.Carota)
@Page(routeOverride = ItemPages.Porri.PAGE_PATH) @Composable
fun PorriExtraItemPage() = ExtraItemPageBody(ItemPages.Porri)
@Page(routeOverride = ItemPages.Peperoni.PAGE_PATH) @Composable
fun PeperoniExtraItemPage() = ExtraItemPageBody(ItemPages.Peperoni)
@Page(routeOverride = ItemPages.Basilico.PAGE_PATH) @Composable
fun BasilicoExtraItemPage() = ExtraItemPageBody(ItemPages.Basilico)
@Page(routeOverride = ItemPages.Zucca.PAGE_PATH) @Composable
fun ZuccaExtraItemPage() = ExtraItemPageBody(ItemPages.Zucca)
@Page(routeOverride = ItemPages.Bietola.PAGE_PATH) @Composable
fun BietolaExtraItemPage() = ExtraItemPageBody(ItemPages.Bietola)
@Page(routeOverride = ItemPages.Patata.PAGE_PATH) @Composable
fun PatataExtraItemPage() = ExtraItemPageBody(ItemPages.Patata)
@Page(routeOverride = ItemPages.Peperoncini.PAGE_PATH) @Composable
fun PeperonciniExtraItemPage() = ExtraItemPageBody(ItemPages.Peperoncini)
@Page(routeOverride = ItemPages.Spinaci.PAGE_PATH) @Composable
fun SpinaciExtraItemPage() = ExtraItemPageBody(ItemPages.Spinaci)
@Page(routeOverride = ItemPages.Lattuga.PAGE_PATH) @Composable
fun LattugaExtraItemPage() = ExtraItemPageBody(ItemPages.Lattuga)
@Page(routeOverride = ItemPages.Cipolle.PAGE_PATH) @Composable
fun CipolleExtraItemPage() = ExtraItemPageBody(ItemPages.Cipolle)
@Page(routeOverride = ItemPages.Cavolfiore.PAGE_PATH) @Composable
fun CavolfioreExtraItemPage() = ExtraItemPageBody(ItemPages.Cavolfiore)
@Page(routeOverride = ItemPages.Rucola.PAGE_PATH) @Composable
fun RucolaExtraItemPage() = ExtraItemPageBody(ItemPages.Rucola)
@Page(routeOverride = ItemPages.Broccoli.PAGE_PATH) @Composable
fun BroccoloExtraItemPage() = ExtraItemPageBody(ItemPages.Broccoli)
@Page(routeOverride = ItemPages.Verza.PAGE_PATH) @Composable
fun VerzaExtraItemPage() = ExtraItemPageBody(ItemPages.Verza)
@Page(routeOverride = ItemPages.Radicchio.PAGE_PATH) @Composable
fun RadicchioExtraItemPage() = ExtraItemPageBody(ItemPages.Radicchio)
@Page(routeOverride = ItemPages.Piselli.PAGE_PATH) @Composable
fun PiselliExtraItemPage() = ExtraItemPageBody(ItemPages.Piselli)
@Page(routeOverride = ItemPages.Aglio.PAGE_PATH) @Composable
fun AglioExtraItemPage() = ExtraItemPageBody(ItemPages.Aglio)
@Page(routeOverride = ItemPages.Bougainvillea.PAGE_PATH) @Composable
fun BougainvilleaExtraItemPage() = ExtraItemPageBody(ItemPages.Bougainvillea)
@Page(routeOverride = ItemPages.Rosa.PAGE_PATH) @Composable
fun RosaExtraItemPage() = ExtraItemPageBody(ItemPages.Rosa)
@Page(routeOverride = ItemPages.Limone.PAGE_PATH) @Composable
fun LimoneExtraItemPage() = ExtraItemPageBody(ItemPages.Limone)
@Page(routeOverride = ItemPages.Arancio.PAGE_PATH) @Composable
fun ArancioExtraItemPage() = ExtraItemPageBody(ItemPages.Arancio)
@Page(routeOverride = ItemPages.Mandarino.PAGE_PATH) @Composable
fun MandarinoExtraItemPage() = ExtraItemPageBody(ItemPages.Mandarino)