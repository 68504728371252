package apace.coltivainarmonia.web.components

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import apace.coltivainarmonia.web.utils.Theme
import com.varabyte.kobweb.compose.css.CSSTextShadow
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextShadow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.theme.colors.ColorScheme
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.css.px

@Composable
fun ClickableColtivareItem(
    modifier: Modifier = Modifier,
    label: String = "",
    clickableText: String,
    clickableTextColor: Color = Colors.White,
    clickableCardColor: ColorScheme = Theme.colorSchemes.white,
    onClick: (SyntheticMouseEvent) -> Unit
) {
    Row (
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically
    ){
        ColtivareText(
            modifier = Modifier.padding(right = 8.px),
            text = label
        )
        ColtivareButton(
            colorScheme = clickableCardColor,
            onClick = onClick,
            content = {
                ColtivareText(
                    modifier = Modifier.padding(leftRight = 12.px, topBottom = 4.px),
                    text = clickableText,
                    color = clickableTextColor,
                    fontWeight = FontWeight.SemiBold,
                    fontSize = 24.px,
                    textShadow = CSSTextShadow(0.px, 2.px, 2.px, Color.rgb(0x000000).copy(alpha = 50)))
            }
        )
    }
}