package apace.coltivainarmonia.web.utils

import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.CSSPercentageNumericValue
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.KeyframesProvider
import org.jetbrains.compose.web.css.CSSAngleValue
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val WobbleKeyframes by Keyframes {
    from { Modifier.rotate((-5).deg) }
    to { Modifier.rotate(5.deg) }
}

val ScaleUpKeyframes by Keyframes {
    from { Modifier.scale(100.percent) }
    to { Modifier.scale(110.percent) }
}

val OffsetYInTopKeyframes by Keyframes {
    from { Modifier.translateY((-100).px) }
    to { Modifier.translateY(0.px) }
}

val OffsetYInBottomKeyframes by Keyframes {
    from { Modifier.translateY((100).px) }
    to { Modifier.translateY(0.px) }
}

val OffsetYInOutOfScreenTopKeyframes by Keyframes {
    from { Modifier.translateY((-200).px).opacity(0f) }
    to { Modifier.translateY(0.px).opacity(1f) }
}

val FadeInKeyFrames by Keyframes {
    from { Modifier.opacity(0f) }
    to { Modifier.opacity(1f) }
}