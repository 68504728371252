package apace.coltivainarmonia.web.components

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.utils.Theme
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLSpanElement

@Composable
fun ColtivareText(
    modifier: Modifier = Modifier,
    text: String,
    fontSize: CSSLengthOrPercentageValue = 16.px,
    fontFamily: String = Theme.type.balooda,
    fontWeight: FontWeight = FontWeight.Normal,
    textDecoration: TextDecorationLine = TextDecorationLine.None,
    textShadow: CSSTextShadow? = null,
    textAlign: TextAlign = TextAlign.Left,
    color: Color = Colors.Black,
    variant: ComponentVariant? = null,
    ref: ElementRefScope<HTMLSpanElement>? = null,
) {
    SpanText(
        modifier = modifier
            .fontSize(fontSize)
            .fontFamily(fontFamily)
            .color(color)
            .fontWeight(fontWeight)
            .textDecorationLine(textDecoration)
            .textAlign(textAlign)
            .then(
                textShadow?.let {
                    Modifier.textShadow(textShadow)
                } ?: Modifier
            ),
        text = text,
        ref = ref,
        variant = variant
    )
}
