package apace.coltivainarmonia.web.pages

import androidx.compose.runtime.*
import apace.coltivainarmonia.web.backgroundLinearGradient
import apace.coltivainarmonia.web.components.objects.LinearGradient
import apace.coltivainarmonia.web.pages.index.AppFeaturesBody
import apace.coltivainarmonia.web.pages.index.BottomItemsBody
import apace.coltivainarmonia.web.pages.index.MainTitleBody
import apace.coltivainarmonia.web.pages.index.TopDownloadNowBanner
import apace.coltivainarmonia.web.utils.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {
    val ctx = rememberPageContext()

    Box(
        modifier = Modifier.fillMaxSize().backgroundColor(Theme.colors.coltivarePrimaryLightest),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundLinearGradient(
                        LinearGradient(
                            from = Theme.colors.coltivarePrimaryLighter,
                            to = Theme.colors.coltivarePrimary
                        )
                    ),
                contentAlignment = Alignment.Center
            ) {
                TopDownloadNowBanner(
                    modifier = Modifier.padding(24.px).align(Alignment.TopEnd),
                    ctx = ctx
                )
                MainTitleBody()
            }

            Box(
                modifier = Modifier.fillMaxWidth().backgroundColor(Theme.colors.coltivarePrimaryLighter),
            ) {
                AppFeaturesBody(
                    modifier = Modifier.align(Alignment.Center).opacity(0f).fillMaxWidth().animation(
                        FadeInKeyFrames.toAnimation(
                            duration = 1.s,
                            delay = 2.s,
                            timingFunction = AnimationTimingFunction.EaseInOut,
                            fillMode = AnimationFillMode.Both
                        )
                    ),
                )
            }

            Box(
                modifier = Modifier.padding(24.px).fillMaxWidth().backgroundLinearGradient(
                    LinearGradient(
                        from = Theme.colors.coltivarePrimary,
                        to = Theme.colors.coltivarePrimaryLightest
                    )
                ),
                contentAlignment = Alignment.Center
            ) {
                BottomItemsBody()
            }
        }
    }


    // TODO fai anche indexing sulle pagine del tipo "COSA COLTIVARE A GENNAIO" raggruppando in filter le robe da ItemPages per mesi

}


