package apace.coltivainarmonia.web.pages.item_pages

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors

interface ItemPage {
    val innerPagePath: String
    val pageTitle: String
    val operation: String
    val operationDescription: String
    val operationMonths: List<String>
    val operationTitleColor: Color

    val operationAndTitle: String get() = ("$operation $pageTitle").lowercase().replaceFirstChar { it.uppercase() }
}

object ItemPages {
    const val EXTRA_STRING =
        "Se vuoi sapere quando poter effettuare altre operazioni e\n" +
                "quali sono le fasi lunari migliori per ogni periodo prova subito la nostra app!"

    val listObjects = listOf(
        Pomodori, Fagiolini, Zucchine, Melanzane, Fagioli, Cetrioli, Prezzemolo, Carota, Porri, Peperoni, Basilico, Zucca, Bietola, Patata, Peperoncini, Spinaci, Cipolle, Lattuga, Cavolfiore, Rucola, Broccoli, Verza, Radicchio, Piselli, Aglio, Bougainvillea, Rosa, Mandarino, Limone, Arancio
    )

    data object Pomodori : ItemPage {
        const val PAGE_PATH = "quando/pomodori"
        override val innerPagePath: String = "quando/pomodori"
        override val pageTitle: String = "Pomodori"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i pomodori?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.Red
    }

    data object Fagiolini : ItemPage {
        const val PAGE_PATH = "quando/fagiolini"
        override val innerPagePath: String = "quando/fagiolini"
        override val pageTitle: String = "Fagiolini"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i fagiolini?"
        override val operationMonths: List<String> = listOf("Aprile", "Maggio", "Giugno")
        override val operationTitleColor: Color = Colors.Green
    }

    data object Zucchine : ItemPage {
        const val PAGE_PATH = "quando/zucchine"
        override val innerPagePath: String = "quando/zucchine"
        override val pageTitle: String = "Zucchine"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le zucchine?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio")
        override val operationTitleColor: Color = Colors.ForestGreen
    }

    data object Melanzane : ItemPage {
        const val PAGE_PATH = "quando/melanzane"
        override val innerPagePath: String = "quando/melanzane"
        override val pageTitle: String = "Melanzane"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le melanzane?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.Purple
    }

    data object Fagioli : ItemPage{
        const val PAGE_PATH = "quando/fagioli"
        override val innerPagePath: String = "quando/fagioli"
        override val pageTitle: String = "Fagioli"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i fagioli?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio", "Giugno")
        override val operationTitleColor: Color = Colors.Brown
    }

    data object Cetrioli : ItemPage{
        const val PAGE_PATH = "quando/cetrioli"
        override val innerPagePath: String = "quando/cetrioli"
        override val pageTitle: String = "Cetrioli"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i cetrioli?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Prezzemolo : ItemPage{
        const val PAGE_PATH = "quando/prezzemolo"
        override val innerPagePath: String = "quando/prezzemolo"
        override val pageTitle: String = "Prezzemolo"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare il prezzemolo?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Carota : ItemPage{
        const val PAGE_PATH = "quando/carota"
        override val innerPagePath: String = "quando/carota"
        override val pageTitle: String = "Carota"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le carote?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio", "Giugno")
        override val operationTitleColor: Color = Colors.Orange
    }

    data object Porri : ItemPage{
        const val PAGE_PATH = "quando/porri"
        override val innerPagePath: String = "quando/porri"
        override val pageTitle: String = "Porri"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i porri?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio", "Giugno", "Luglio")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Peperoni : ItemPage{
        const val PAGE_PATH = "quando/peperoni"
        override val innerPagePath: String = "quando/peperoni"
        override val pageTitle: String = "Peperoni"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i peperoni?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.Red
    }

    data object Basilico : ItemPage{
        const val PAGE_PATH = "quando/basilico"
        override val innerPagePath: String = "quando/basilico"
        override val pageTitle: String = "Basilico"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare il basilico?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Zucca : ItemPage{
        const val PAGE_PATH = "quando/zucca"
        override val innerPagePath: String = "quando/zucca"
        override val pageTitle: String = "Zucca"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le zucche?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.Orange
    }

    data object Bietola : ItemPage{
        const val PAGE_PATH = "quando/bietola"
        override val innerPagePath: String = "quando/bietola"
        override val pageTitle: String = "Bietola"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le bietole?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Patata : ItemPage{
        const val PAGE_PATH = "quando/patate"
        override val innerPagePath: String = "quando/patate"
        override val pageTitle: String = "Patate"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le patate?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.Brown
    }

    data object Peperoncini : ItemPage{
        const val PAGE_PATH = "quando/peperoncini"
        override val innerPagePath: String = "quando/peperoncini"
        override val pageTitle: String = "Peperoncini"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i peperoncini?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.OrangeRed
    }

    data object Spinaci : ItemPage{
        const val PAGE_PATH = "quando/spinaci"
        override val innerPagePath: String = "quando/spinaci"
        override val pageTitle: String = "Spinaci"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare gli spinaci?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio", "Agosto", "Settembre", "Ottobre")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Cipolle : ItemPage{
        const val PAGE_PATH = "quando/cipolle"
        override val innerPagePath: String = "quando/cipolle"
        override val pageTitle: String = "Cipolle"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare le cipolle?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.RebeccaPurple
    }

    data object Lattuga : ItemPage{
        const val PAGE_PATH = "quando/lattuga"
        override val innerPagePath: String = "quando/lattuga"
        override val pageTitle: String = "Lattuga"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare la lattuga?"
        override val operationMonths: List<String> = listOf("Gennaio", "Febbraio", "Marzo", "Aprile")
        override val operationTitleColor: Color = Colors.SeaGreen
    }

    data object Cavolfiore : ItemPage{
        const val PAGE_PATH = "quando/cavolfiore"
        override val innerPagePath: String = "quando/cavolfiore"
        override val pageTitle: String = "Cavolfiore"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare il cavolfiore?"
        override val operationMonths: List<String> = listOf("Aprile", "Maggio", "Giugno")
        override val operationTitleColor: Color = Colors.DarkSlateGray
    }

    data object Rucola : ItemPage{
        const val PAGE_PATH = "quando/rucola"
        override val innerPagePath: String = "quando/rucola"
        override val pageTitle: String = "Rucola"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare la rucola?"
        override val operationMonths: List<String> = listOf("Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre")
        override val operationTitleColor: Color = Colors.DarkGreen
    }

    data object Broccoli : ItemPage{
        const val PAGE_PATH = "quando/broccoli"
        override val innerPagePath: String = "quando/broccoli"
        override val pageTitle: String = "Broccoli"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i broccoli?"
        override val operationMonths: List<String> = listOf("Maggio", "Giugno", "Luglio")
        override val operationTitleColor: Color = Colors.DarkOliveGreen
    }

    data object Verza : ItemPage{
        const val PAGE_PATH = "quando/verza"
        override val innerPagePath: String = "quando/verza"
        override val pageTitle: String = "Verza"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare la verza?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio")
        override val operationTitleColor: Color = Color.rgb(0x6ea150)
    }

    data object Radicchio : ItemPage{
        const val PAGE_PATH = "quando/radicchio"
        override val innerPagePath: String = "quando/radicchio"
        override val pageTitle: String = "Radicchio"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare il radicchio?"
        override val operationMonths: List<String> = listOf("Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre")
        override val operationTitleColor: Color = Colors.IndianRed
    }

    data object Piselli: ItemPage{
        const val PAGE_PATH = "quando/piselli"
        override val innerPagePath: String = "quando/piselli"
        override val pageTitle: String = "Piselli"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare i piselli?"
        override val operationMonths: List<String> = listOf("Febbraio", "Marzo", "Aprile", "Ottobre", "Novembre")
        override val operationTitleColor: Color = Colors.Green
    }

    data object Aglio: ItemPage{
        const val PAGE_PATH = "quando/aglio"
        override val innerPagePath: String = "quando/aglio"
        override val pageTitle: String = "Aglio"
        override val operation: String = "Seminare"
        override val operationDescription: String = "Quando potresti seminare l'aglio?"
        override val operationMonths: List<String> = listOf("Gennaio", "Febbraio", "Marzo", "Ottobre", "Novembre", "Dicembre")
        override val operationTitleColor: Color = Color.rgb(0xb5b52b)
    }

    data object Bougainvillea: ItemPage {
        const val PAGE_PATH = "quando/bougainvillea"
        override val innerPagePath: String = "quando/bougainvillea"
        override val pageTitle: String = "Bougainvillea"
        override val operation: String = "Piantare"
        override val operationDescription: String = "Quando potresti piantare la bougainvillea?"
        override val operationMonths: List<String> = listOf("Maggio", "Settembre")
        override val operationTitleColor: Color = Color.rgb(0xc733cc)
    }

    data object Rosa: ItemPage {
        const val PAGE_PATH = "quando/rosa"
        override val innerPagePath: String = "quando/rosa"
        override val pageTitle: String = "Rosa"
        override val operation: String = "Piantare"
        override val operationDescription: String = "Quando potresti piantare la rosa?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Maggio", "Ottobre", "Novembre")
        override val operationTitleColor: Color = Colors.DarkRed
    }

    data object Mandarino: ItemPage {
        const val PAGE_PATH = "quando/mandarino"
        override val innerPagePath: String = "quando/mandarino"
        override val pageTitle: String = "Mandarino"
        override val operation: String = "Potare"
        override val operationDescription: String = "Quando potresti potare il mandarino?"
        override val operationMonths: List<String> = listOf("Aprile", "Maggio", "Giugno")
        override val operationTitleColor: Color = Colors.Coral
    }

    data object Limone: ItemPage {
        const val PAGE_PATH = "quando/limone"
        override val innerPagePath: String = "quando/limone"
        override val pageTitle: String = "Limone"
        override val operation: String = "Potare"
        override val operationDescription: String = "Quando potresti potare il limone?"
        override val operationMonths: List<String> = listOf("Aprile")
        override val operationTitleColor: Color = Colors.Orange
    }

    data object Arancio: ItemPage {
        const val PAGE_PATH = "quando/arancio"
        override val innerPagePath: String = "quando/arancio"
        override val pageTitle: String = "Arancio"
        override val operation: String = "Potare"
        override val operationDescription: String = "Quando potresti potare l'arancio?"
        override val operationMonths: List<String> = listOf("Marzo", "Aprile", "Giugno")
        override val operationTitleColor: Color = Colors.Coral
    }

}