package apace.coltivainarmonia.web.components.objects

import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import org.jetbrains.compose.web.css.CSSColorValue

data class LinearGradient(
    val dir: LinearGradient.Direction = LinearGradient.Direction.ToBottom,
    val from: CSSColorValue,
    val to: CSSColorValue
){
    fun toLinearGradient() = linearGradient(dir) {
        add(from)
        add(to)
    }
}