package apace.coltivainarmonia.web.pages.index

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.components.AppFeatureCard
import apace.coltivainarmonia.web.components.ColtivareText
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px

@Composable
fun AppFeaturesBody(
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        ColtivareText(
            modifier = Modifier.padding(top = 24.px, bottom = 16.px),
            text = "Cosa troverai", // Se vuoi scoprire quando | Desideri conoscere quando | Hai dubbi su quando | Vorresti sapere quando | La luna risponde
            fontSize = 16.px,
            fontWeight = FontWeight.Bold,
            color = Colors.White
        )
        AppFeaturesCards(modifier = Modifier.padding(top = 8.px, bottom = 16.px))

        ColtivareText(
            modifier = Modifier.padding(top = 8.px, bottom = 16.px),
            text = "...e molto altro ancora!", // Se vuoi scoprire quando | Desideri conoscere quando | Hai dubbi su quando | Vorresti sapere quando | La luna risponde
            fontSize = 16.px,
            fontWeight = FontWeight.Bold,
            color = Colors.White
        )
    }
}


@Composable
private fun AppFeaturesCards(
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap),
        horizontalArrangement = Arrangement.SpaceEvenly,
    ) {
        AppFeatureCard(
            topSpacing = 8.px,
            text = "Tanti ortaggi, frutti e fiori",
            textColor = Colors.White,
            gradientTop = Color.rgb(0x4ead34),
            gradientBottom = Color.rgb(0x20854b)
        )
        AppFeatureCard(
            topSpacing = 8.px,
            text = "Calendario lunare",
            textColor = Colors.White,
            gradientTop = Color.rgb(0x9152ff),
            gradientBottom = Color.rgb(0x520073)
        )
        AppFeatureCard(
            topSpacing = 8.px,
            text = "Notifiche per i periodi migliori",
            textColor = Colors.White,
            gradientTop = Color.rgb(0xdea83c),
            gradientBottom = Color.rgb(0xe08a19)
        )
        AppFeatureCard(
            topSpacing = 8.px,
            text = "Meteo a portata di mano",
            textColor = Colors.White,
            gradientTop = Color.rgb(0x6bdaff),
            gradientBottom = Color.rgb(0x0065d9)
        )
        AppFeatureCard(
            topSpacing = 8.px,
            text = "Eventi personalizzati",
            textColor = Colors.White,
            gradientTop = Color.rgb(0x1ac47b),
            gradientBottom = Color.rgb(0x13bf47)
        )
    }
}
