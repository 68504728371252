package apace.coltivainarmonia.web.components

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.backgroundLinearGradient
import apace.coltivainarmonia.web.components.objects.BoxShadow
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times

@Composable
fun Card(
    modifier: Modifier = Modifier,
    color: Color,
    radius: CSSLengthOrPercentageValue = 16.px,
    content: @Composable () -> Unit
){
    Box(
        modifier = modifier.borderRadius(radius).backgroundColor(color)
    ) {
        content.invoke()
    }
}

@Composable
fun Card(
    modifier: Modifier = Modifier,
    linearGradient: apace.coltivainarmonia.web.components.objects.LinearGradient,
    elevation: CSSLengthNumericValue = 0.px,
    radius: CSSLengthOrPercentageValue = 16.px,
    content: @Composable () -> Unit
){
    Box(
        modifier = modifier
            .borderRadius(radius)
            .then(BoxShadow(
                offsetY = elevation.times(0.75),
                blurRadius = elevation.times(1.5),
                spreadRadius = elevation.times(0.5),
                color = Colors.Black.copy(alpha = 75)
            ).toBoxShadow())
            //.boxShadow(blurRadius = 10.px, spreadRadius = 2.px, offsetY = 2.px, color = Colors.Black.copy(alpha = 50))
            .backgroundLinearGradient(linearGradient)
    ) {
        content.invoke()
    }
}
