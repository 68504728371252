package apace.coltivainarmonia.web.components.objects

import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.boxShadow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.styleModifier
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.px

data class BoxShadow(
    val offsetX: CSSLengthNumericValue = 0.px,
    val offsetY: CSSLengthNumericValue = 0.px,
    val blurRadius: CSSLengthNumericValue? = null,
    val spreadRadius: CSSLengthNumericValue? = null,
    val color: CSSColorValue? = null,
    val inset: Boolean = false,
) {
    fun toBoxShadow() = Modifier.boxShadow(offsetX, offsetY, blurRadius, spreadRadius, color, inset)
}
