package apace.coltivainarmonia.web.pages.index

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.components.Card
import apace.coltivainarmonia.web.components.ClickableColtivareItem
import apace.coltivainarmonia.web.components.ColtivareButton
import apace.coltivainarmonia.web.components.ColtivareText
import apace.coltivainarmonia.web.components.objects.LinearGradient
import apace.coltivainarmonia.web.pages.item_pages.ItemPages
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px

@Composable
fun BottomItemsBody(
    modifier: Modifier = Modifier
) {
    val ctx = rememberPageContext()

    Column(
        modifier = modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        ColtivareText(
            text = "Vorresti sapere quando", // Se non sai quando | Se vuoi scoprire quando | Desideri conoscere quando | Hai dubbi su quando | Vorresti sapere quando | La luna risponde
            fontSize = 42.px,
            fontWeight = FontWeight.Bold,
            color = Colors.White,
            textAlign = TextAlign.Center
        )

        Row(
            modifier = Modifier.fillMaxWidth().flexFlow(flexDirection = FlexDirection.Row, flexWrap = FlexWrap.Wrap),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            ItemPages.listObjects.forEach { itemPage ->
                ClickableColtivareItem(
                    modifier = Modifier.padding(top = 8.px),
                    clickableText = itemPage.operationAndTitle,
                    clickableTextColor = itemPage.operationTitleColor,
                    onClick = {
                        ctx.router.navigateTo(itemPage.innerPagePath)
                    }
                )
            }
        }
    }

}