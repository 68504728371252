package apace.coltivainarmonia.web.components

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.components.objects.LinearGradient
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jetbrains.compose.web.css.px

@Composable
fun AppFeatureCard(
    topSpacing: CSSLengthNumericValue = 0.px,
    text: String,
    textColor: Color = Colors.Black,
    gradientTop: Color,
    gradientBottom: Color
){
    Column {
        Box(Modifier.height(topSpacing)) {  }
        Card(
            linearGradient = LinearGradient(
                from = gradientTop,//Color.rgb(0xfdff70),
                to = gradientBottom, //Color.rgb(0xb0f000)
            ),
            radius = 12.px,
            elevation = 2.px
        ) {
            ColtivareText(
                modifier = Modifier.padding(topBottom = 16.px, leftRight = 24.px),
                color = textColor,
                text = text,//"Tanti ortaggi, frutti e fiori",
                fontSize = 16.px,
                fontWeight = FontWeight.Bold
            )
        }
    }
}