package apace.coltivainarmonia.web.pages.index

import androidx.compose.runtime.Composable
import apace.coltivainarmonia.web.components.ColtivareText
import apace.coltivainarmonia.web.utils.*
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.AnimationFillMode
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@Composable
fun MainTitleBody() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier.animation(
                OffsetYInTopKeyframes.toAnimation(
                    duration = 2.s,
                    iterationCount = AnimationIterationCount.of(1),
                    timingFunction = AnimationTimingFunction.EaseInOut,
                )
            ),
            src = R.drawable.moonBg
        )
        ColtivareText(
            modifier = Modifier.padding(top = 16.px, bottom = 16.px).animation(
                OffsetYInOutOfScreenTopKeyframes.toAnimation(
                    duration = 2.s,
                    iterationCount = AnimationIterationCount.of(1),
                    timingFunction = AnimationTimingFunction.EaseInOut,
                )
            ),
            text = "Coltivare con la luna",
            fontFamily = Theme.type.fraunces,
            textAlign = TextAlign.Center,
            fontSize = 48.px,
            color = Theme.colors.coltivareLightYellow
        )
        Image(
            modifier = Modifier.height(58.px).opacity(0f).animation(
                FadeInKeyFrames.toAnimation(
                    delay = 1.s,
                    duration = 1.s,
                    iterationCount = AnimationIterationCount.of(1),
                    timingFunction = AnimationTimingFunction.EaseInOut,
                    fillMode = AnimationFillMode.Both
                )
            ),
            src = R.drawable.ortaggi
        )
    }
}