package apace.coltivainarmonia.web.components

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import apace.coltivainarmonia.web.utils.Theme
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorScheme
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.px

@Composable
fun ColtivareButton(
    modifier: Modifier = Modifier,
    colorScheme: ColorScheme = Theme.colorSchemes.coltivareGreen,
    onClick: (SyntheticMouseEvent) -> Unit,
    content: @Composable () -> Unit
) {
    Button(
        modifier = modifier.borderRadius(200.px).alignItems(AlignItems.Center),
        colorScheme = colorScheme,
        onClick = onClick
    ) {
        content.invoke()
    }
}