import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { apace.coltivainarmonia.web.pages.HomePage() }
        ctx.router.register("/quando/aglio") { apace.coltivainarmonia.web.pages.AglioExtraItemPage()
                }
        ctx.router.register("/quando/arancio") {
                apace.coltivainarmonia.web.pages.ArancioExtraItemPage() }
        ctx.router.register("/quando/basilico") {
                apace.coltivainarmonia.web.pages.BasilicoExtraItemPage() }
        ctx.router.register("/quando/bietola") {
                apace.coltivainarmonia.web.pages.BietolaExtraItemPage() }
        ctx.router.register("/quando/bougainvillea") {
                apace.coltivainarmonia.web.pages.BougainvilleaExtraItemPage() }
        ctx.router.register("/quando/broccoli") {
                apace.coltivainarmonia.web.pages.BroccoloExtraItemPage() }
        ctx.router.register("/quando/carota") {
                apace.coltivainarmonia.web.pages.CarotaExtraItemPage() }
        ctx.router.register("/quando/cavolfiore") {
                apace.coltivainarmonia.web.pages.CavolfioreExtraItemPage() }
        ctx.router.register("/quando/cetrioli") {
                apace.coltivainarmonia.web.pages.CetrioliExtraItemPage() }
        ctx.router.register("/quando/cipolle") {
                apace.coltivainarmonia.web.pages.CipolleExtraItemPage() }
        ctx.router.register("/quando/fagioli") {
                apace.coltivainarmonia.web.pages.FagioliExtraItemPage() }
        ctx.router.register("/quando/fagiolini") {
                apace.coltivainarmonia.web.pages.FagioliniExtraItemPage() }
        ctx.router.register("/quando/lattuga") {
                apace.coltivainarmonia.web.pages.LattugaExtraItemPage() }
        ctx.router.register("/quando/limone") {
                apace.coltivainarmonia.web.pages.LimoneExtraItemPage() }
        ctx.router.register("/quando/mandarino") {
                apace.coltivainarmonia.web.pages.MandarinoExtraItemPage() }
        ctx.router.register("/quando/melanzane") {
                apace.coltivainarmonia.web.pages.MelanzaneExtraItemPage() }
        ctx.router.register("/quando/patate") {
                apace.coltivainarmonia.web.pages.PatataExtraItemPage() }
        ctx.router.register("/quando/peperoncini") {
                apace.coltivainarmonia.web.pages.PeperonciniExtraItemPage() }
        ctx.router.register("/quando/peperoni") {
                apace.coltivainarmonia.web.pages.PeperoniExtraItemPage() }
        ctx.router.register("/quando/piselli") {
                apace.coltivainarmonia.web.pages.PiselliExtraItemPage() }
        ctx.router.register("/quando/pomodori") {
                apace.coltivainarmonia.web.pages.PomodoriExtraItemPage() }
        ctx.router.register("/quando/porri") { apace.coltivainarmonia.web.pages.PorriExtraItemPage()
                }
        ctx.router.register("/quando/prezzemolo") {
                apace.coltivainarmonia.web.pages.PrezzemoloExtraItemPage() }
        ctx.router.register("/quando/radicchio") {
                apace.coltivainarmonia.web.pages.RadicchioExtraItemPage() }
        ctx.router.register("/quando/rosa") { apace.coltivainarmonia.web.pages.RosaExtraItemPage() }
        ctx.router.register("/quando/rucola") {
                apace.coltivainarmonia.web.pages.RucolaExtraItemPage() }
        ctx.router.register("/quando/spinaci") {
                apace.coltivainarmonia.web.pages.SpinaciExtraItemPage() }
        ctx.router.register("/quando/verza") { apace.coltivainarmonia.web.pages.VerzaExtraItemPage()
                }
        ctx.router.register("/quando/zucca") { apace.coltivainarmonia.web.pages.ZuccaExtraItemPage()
                }
        ctx.router.register("/quando/zucchine") {
                apace.coltivainarmonia.web.pages.ZucchineExtraItemPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.WobbleKeyframes)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.ScaleUpKeyframes)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.OffsetYInTopKeyframes)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.OffsetYInBottomKeyframes)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.OffsetYInOutOfScreenTopKeyframes)
        ctx.stylesheet.registerKeyframes(apace.coltivainarmonia.web.utils.FadeInKeyFrames)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Coltivare con la luna"))
    renderComposable(rootElementId = "root") {
        apace.coltivainarmonia.web.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
